body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f7f9;
  color: black !important;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*-------------------------------
               registration 
  -------------------------------*/
#registration-page {
  margin-top: 20px;
}
.form-signin {
  color: black;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.line{
  width: 112px;
  height: 47px;
  border-bottom: 1px solid black;
  position: absolute;
  margin: '10px';
  }
.form-signin .form-group,
.form-signin > label,
.form-signin button {
  margin-bottom: 10px;
}
.form-signin .form-group label {
  color: black;
  font-weight: normal;
}
label {
  font-weight: normal;
}

.form-control {
  color: black;
  border-radius: 8px;
}

.account-wall {
  margin-top: 20px;
  padding: 40px 0px 0px 0px;
  /* background-color: #003f5c; */
  box-shadow: 0px -1px 5px #282c33;
  border-radius: 20px;
  margin-bottom: 20px;
}

/*-------------------------------
               registration 
  -------------------------------*/

/*-------------------------------
               Header 
  -------------------------------*/
#user-name {
  text-align: left;
}
.dropdown-menu {
  background: #31353d;
}
.dropdown-menu li a {
  color: #fff;
  width: 100%;
  font-size: 0.9em;
  padding: 5px 10px;
  text-align: left;
}
.dropdown-menu li a:hover {
  background-color: #17202a;
  color: #fff;
}
.app-header span {
  cursor: pointer;
}
.app-header {
  position: fixed;
  top: 0;
  color: #fff;
  text-align: right;
  padding: 19px;
  background: #fff;
  width: 100% !important;
  /* overflow: auto; */
  z-index: 1000;
  background: #f6f7f9;
  box-shadow: 0px -1px 5px #282c33;
}
.app-header .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}
.app-header a {
  width: 5%;
  text-align: center;
  padding: 5px 0;
  transition: all 0.3s ease;
  color: white;
  font-size: 16px;
}
.app-header a:hover {
  /* opacity: 0.7; */
  text-decoration: none;
}
.green_icon {
  background-color: #4cd137;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.red_icon {
  background-color: #d13737;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.user-info {
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s all;
}
.user-info:hover {
  background: #eee;
}
.user-info img {
  margin-top: -5px;
  margin-left: 10px;
  margin-right: 10px;
}
.user-info i {
  margin-left: 10px;
}
.user-info a {
  color: black;
}
.user-info:focus {
  text-decoration: none;
}
/*-------------------------------
               Header 
  -------------------------------*/

/* -----------------------------
              SideBar 
  -------------------------------*/
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#close-sidebar {
  width: 100%;
}
#close-sidebar i {
  float: right;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 230px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 5px;
  border-radius: 0 4px 4px 0px;
  width: 40px;
  transition-delay: 0.3s;
  z-index: 1000;
  color: black;
}
#show-sidebar:hover {
  /* color: #fff; */
  opacity: 0.6;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 200px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1000;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 5px 15px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
  /* padding: 10px; */
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
  /* padding-top: 10px; */
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li {
  /* margin-bottom: 10px; */
  color: #fff;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 12px;
  color: #fff;
}

.sidebar-wrapper .sidebar-menu ul li a img {
  margin-right: 10px;
  font-size: 12px;
  width: 15px;
  height: 15px;
  line-height: 30px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 20px;
  height: 30px;
  line-height: 40px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover {
  background: #11293f;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  padding-top: 90px;
  padding-right: 20px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #363d48;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #363d48;
}
::-webkit-scrollbar-thumb:active {
  background: #363d48;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
  margin: 0 15px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
  /* background: #003f5c; */
  background: #051e34 !important;
  background-color: #fff;
  box-shadow: 0px -1px 5px #282c33;
}
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  color: #fff;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #fff;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  /* color: #ffffff; */
  opacity: 0.9;
}

/* .chiller-theme .sidebar-wrapper .sidebar-menu ul li a i, */
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #fff;
}

.chiller-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}

.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}
.sidenav-active {
  color: #fff;
  background: #11293f;
  /* border-left: 4px solid #fff; */
}
.sidenav-active,
.sidenav-active span {
  /* color: #fff; */
  opacity: 0.9;
}
/*-------------------------------------
              common
  --------------------------------------*/
.userDetailsFields {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.userDetailsFields label {
  width: 120px;
  font-weight: bolder;
}
.sidebar-menu ul li i {
  font-size: 1.2em !important;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#statistics-chart,
#genders-chart,
#countries-chart {
  height: 300px !important;
}

.card {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  /* margin-top: 10px; */
  margin-bottom: 20px;
}

.comp-header {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 10px;
}

::placeholder {
  color: gray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray !important;
}
/*-------------------------------------
              common
  --------------------------------------*/

/*-------------------------------------
              dashboard
  --------------------------------------*/
.wrimagecard {
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: left;
  position: relative;
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
  border-radius: 10px;
  transition: all 0.3s ease;
}
.wrimagecard .fa {
  position: relative;
  font-size: 70px;
}
.wrimagecard .glyphicon {
  position: relative;
  font-size: 65px;
}
.wrimagecard-topimage_header {
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
a.wrimagecard:hover,
.wrimagecard-topimage:hover {
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}
.wrimagecard-topimage_title {
  padding: 20px 24px;
  height: 80px;
  padding-bottom: 0.75rem;
  position: relative;
}
.wrimagecard-topimage a {
  width: 100%;
  height: 100%;
  display: block;
  border-bottom: none;
  text-decoration: none;
  color: #525c65;
  transition: color 0.3s ease;
}
/*-------------------------------------
              dashboard
  --------------------------------------*/

.MuiSvgIcon-root {
  color: #3f51b5;
  width: 1.4em !important;
  height: 1.4em !important;
}
.MuiTypography-body1 {
  font-size: 1.1em !important;
}
.MuiTooltip-popper,
.MuiTooltip-popperInteractive,
.MuiTooltip-popperArrow {
  width: 60px !important;
  height: 20px !important;
  font-size: 1.3em !important;
}
.MuiIconButton-root {
  font-size: 1.3em !important;
}

.MuiTooltip-popperArrow {
  padding: 9px !important;
}
.MuiPopover-paper {
  color: #fff !important;
}
.MuiTableCell-sizeSmall {
  padding: 4px 6px 4px 16px !important;
}
.MuiTablePagination-root div p,
.MuiTablePagination-selectRoot {
  /* color: #fff !important; */
  font-size: 1.5em !important;
}

.MuiTableCell-head {
  font-size: 0.8em !important;
}
.MuiTableCell-body {
  font-size: 0.9em !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  /* color: #1e75ff !important; */
  opacity: 0.7;
}
.MuiTablePagination-toolbar .MuiTypography-root p {
  font-size: 1em !important;
}
.MuiTableContainer-root table .MuiTableBody-root tr:hover {
  background-color: #fafafa !important;
  cursor: pointer;
}
.MuiTablePagination-menuItem {
  color: black !important;
}

.MuiTableContainer-root table .MuiTableHead-root tr {
  background: #fafafa !important;
  /* background-color: rgba(98, 103, 112, 0.1) !important; */
  margin-top: 10px !important;
}
.MuiTableContainer-root table .MuiTableBody-root tr td {
  height: auto !important;
}
.MuiTableContainer-root table .MuiTableBody-root tr {
  height: 65px !important;
}
.MuiTablePagination-actions button:hover {
  /* background-color: #363d48 !important; */
  cursor: pointer;
}
.MuiButtonBase-root.Mui-disabled {
  pointer-events: visible !important;
  cursor: not-allowed !important;
}
.MuiTablePagination-toolbar {
  /* border-top: 1px solid #fff !important; */
  text-align: center !important;
}
.MuiTableRow-root.Mui-selected {
  background-color: #fff !important;
}
.MuiPaper-root {
  padding: 10px 0 !important;
  margin-top: 0px !important;
}
.MuiTableContainer-root
  input[type="checkbox"]
  .MuiTableContainer-root
  table
  tr
  td:first-child
  .MuiSvgIcon-root {
  color: #1a70cf !important;
}
#all-videos-heading {
  margin-bottom: 20px;
}
.actions {
  float: right;
}
.actions .inputs select {
  margin-bottom: 5px;
  width: 220px;
  display: inline;
  margin-right: 5px;
}
#video-card {
  padding-bottom: 10px;
  margin-bottom: 40px;
  transition: 0.3s all;
}
#video-card:hover {
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}
#video-card img {
  width: 100%;
  height: 150px;
}
#video-card video {
  /* width: 345px; */
  width: 100%;
}
.delete-video {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.delete-video button {
  /* width: 130px; */
  margin-right: 5px;
  margin-bottom: 5px;
}
.pagination-section {
  width: 100%;
  text-align: right;
}
.pagination-section select {
  width: 100px;
  margin-right: 10px;
}
.pagination-section select,
.pagination-section button {
  display: inline;
  vertical-align: text-bottom;
}
/*-------------------------------------
              media queries 
  --------------------------------------*/
#transaction-footer {
  display: none;
}
@media screen and (max-width: 768px) {
  .page-wrapper .page-content {
    padding-left: 10px;
    padding-top: 70px;
    padding-right: 10px;
  }
}
.table-responsive {
  border: none;
  border-radius: 20px !important;
}
.card {
  height: auto;
  padding: 10px;
}
.actions .inputs select {
  display: block;
  width: 100%;
  float: left;
}
#all-videos-heading {
  margin-bottom: 120px !important;
}

.limiter a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.limiter a:focus {
  outline: none !important;
}

.limiter a:hover {
  text-decoration: none;
  color: #57b846;
}

/*---------------------------------------------*/
.limiter input {
  outline: none;
  border: none;
}

.limiter input:focus {
  border-color: transparent !important;
}

.limiter input:focus::-webkit-input-placeholder {
  color: transparent;
}
.limiter input:focus:-moz-placeholder {
  color: transparent;
}
.limiter input:focus::-moz-placeholder {
  color: transparent;
}
.limiter input:focus:-ms-input-placeholder {
  color: transparent;
}

.limiter input::-webkit-input-placeholder {
  color: #999999;
}
.limiter input:-moz-placeholder {
  color: #999999;
}
.limiter input::-moz-placeholder {
  color: #999999;
}
.limiter input:-ms-input-placeholder {
  color: #999999;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #001628;
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 100px 130px 100px 95px;
}
.login100-form {
  width: 290px;
}

.login100-pic {
  /* margin-top: 0px; */
  width: 200px;
}

.login100-pic img {
  max-width: 100%;
}
.login100-form-title {
  font-size: 24px;
  color: #333333;
  /* line-height: 1.2; */
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 20px;
}

.botton-links {
  margin-top: 20px;
}
/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input100 {
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 1.5;
  color: #666666;

  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 10px;
  padding: 0 30px 0 68px;
}

/*------------------------------------------------------------------
      [ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87, 184, 70, 0.8);
}

.input100:focus + .focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
  color: #57b846;
  padding-left: 28px;
}

/*------------------------------------------------------------------
      [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #57b846;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
      [ Responsive ]*/

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 177px 90px 33px 85px;
  }

  .login100-pic {
    width: 35%;
  }

  .login100-form {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 100px 80px 33px 80px;
  }

  .login100-pic {
    display: none;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 100px 15px 33px 15px;
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 13px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
  pointer-events: none;

  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 13px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

#dashboard .view-filter {
  display: none;
}

#dashboard h5 {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.no-data {
  width: 100%;
  text-align: center;
}

.display-inline {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.display-inline .field {
  font-weight: bold;
}
